import {
  Component,
  OnInit,
  SimpleChanges,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  signal,
  inject,
  DestroyRef,
} from '@angular/core';
import {
  Validators,
  FormGroup,
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  User,
  Channel,
  Maybe,
  Layout,
  GetAssignableLayoutsGQL,
  ChannelType,
} from '@designage/gql';
import {
  NgbDropdownModule,
  NgbModal,
  NgbPopoverModule,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import {
  IChannelForm,
  ICountry,
  ICreateChannelForm,
} from '@desquare/interfaces';
import { CurrentUserService } from '@desquare/services';
import { LayoutManageDialogComponent } from '@designage/app/layout/layout-manage-dialog/layout-manage-dialog.component';
import { lastValueFrom } from 'rxjs';
import { NgClass } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';
import { LayoutExplorerComponent } from '@desquare/components/common/src/layout-explorer/layout-explorer.component';
import { SmartDateTimeComponent } from '@desquare/components/common/src/smart-date-time/smart-date-time.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

type ChannelForm = Channel & ICreateChannelForm;

@Component({
  standalone: true,
  imports: [
    NgClass,
    FormsModule,
    TranslatePipe,
    ReactiveFormsModule,
    NgbPopoverModule,
    NgbDropdownModule,
    LayoutExplorerComponent,
    SmartDateTimeComponent,
    NgbTooltip,
  ],
  selector: 'app-channel-form',
  templateUrl: './channel-form.component.html',
  styleUrls: ['./channel-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelFormComponent implements OnInit, OnChanges {
  destroyRef = inject(DestroyRef);
  @ViewChild('layoutExplorer') layoutExplorer!: ElementRef;

  @Output() submitted = new EventEmitter<IChannelForm>();
  @Output() values = new EventEmitter<IChannelForm>();
  @Output() valid = new EventEmitter<boolean>();
  @Output() pristine = new EventEmitter<boolean>();
  @Output() loading = new EventEmitter<boolean>();
  @Output() loaderMessage = new EventEmitter<string>();
  @Output() explorerSelectedRegion = new EventEmitter<string>();

  @Input() type?: string;
  @Input() isDashboardView = false;
  @Input() showSelectionOnly = false;
  @Input() channel!: Channel;
  @Input() profileId!: Maybe<string>;
  @Input() formId: string = 'channel';
  @Input() parentFormGroup?: FormGroup;
  @Input() settingsManager = true;
  @Input() layoutManager = true;
  @Input() canSelectRegions = false;
  @Input() canSetLayout = true;
  @Input() showMiniLayoutExplorer = true;
  @Input() explorerSize: string = '250px';
  @Input() selectedRegion!: string | null;
  @Input() layout: Maybe<Layout>;

  channelForm!: FormGroup;
  currentUser!: Maybe<User>;
  countries!: ICountry[];

  layouts = signal<Layout[]>([]);
  layoutExplorerInputWidth!: string;
  layoutExplorerInputHeight!: string;
  layoutExplorerBoundingBox!: string;

  get controlGroupClass() {
    return this.isDashboardView ? 'col-12' : 'col-6';
  }
  /** Event Channel type */
  get isEvent() {
    return (
      this.type === ChannelType.Event ||
      this.channel?.type === ChannelType.Event
    );
  }

  get publicLayouts() {
    return this.getPublicLayouts(this.layouts());
  }

  get customLayouts() {
    return this.layouts()?.filter((x) => !x.public);
  }

  constructor(
    private currentUserService: CurrentUserService,
    private formBuilder: FormBuilder,
    private getAssignableLayouts: GetAssignableLayoutsGQL,
    private modalService: NgbModal,
  ) {}

  async ngOnInit() {
    this.initVariables();
    this.initSubscriptions();
    this.initForm();
    this.setFormState();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initForm();

    if (changes.channel && changes.channel.currentValue && this.channel) {
      this.setControlValues();
    }
    if (changes.parentFormGroup && changes.parentFormGroup.currentValue) {
      this.parentFormGroup = changes.parentFormGroup.currentValue;
    }
  }

  async initVariables() {
    this.valid.emit(false);
    this.pristine.emit(true);
  }

  initForm() {
    if (this.channelForm) return;

    const FORM_GROUP_NAME = this.formId || 'channel';

    this.channelForm =
      (this.parentFormGroup?.controls[FORM_GROUP_NAME] as FormGroup) ??
      this.formBuilder.group({
        profileId: [this.profileId || null, Validators.required],
        name: [null, Validators.required],
        description: [null],
        layoutId: [null],
        layoutName: [null],
        layoutOrientation: [null],
        type: this.type,
        eventStart: [null, this.isEvent ? Validators.required : undefined],
        eventEnd: [null],
      });

    if (this.parentFormGroup)
      this.parentFormGroup.addControl(FORM_GROUP_NAME, this.channelForm);

    this.setControlValues();
  }

  resetForm() {
    console.log('resetForm');
    this.channelForm.reset({
      profileId: this.profileId || null,
      name: this.channel?.name,
      description: this.channel?.description,
      layoutId: this.channel?.layout?.id || null,
      layoutName: this.channel?.layout?.name || null,
      eventStart: this.channel?.eventStart || null,
      eventEnd: this.channel?.eventEnd || null,
    });
    this.initVariables();
    // this.initForm();
    // this.setControlValues();
  }

  async initSubscriptions() {
    // this.subs.sink = this.store
    //   .pipe(select(fromUser.selectActiveUser))
    //   .subscribe((userState) => {
    //     this.currentUser = userState?.data || null;
    //   });

    await this.initLayouts();
  }
  async initLayouts() {
    const profileId = this.currentUserService.getCurrentProfileId();

    const { data } = await lastValueFrom(
      this.getAssignableLayouts.fetch(
        { id: profileId },
        { fetchPolicy: 'network-only' },
      ),
    );

    this.layouts.set(data.profile?.assignableLayouts || []);

    if (!this.channel?.layout && this.layouts().length > 0) {
      const publicLayouts = this.getPublicLayouts(this.layouts());

      const firstPublicLayout = publicLayouts
        ? publicLayouts.length
          ? publicLayouts[0]
          : undefined
        : undefined;
      this.setLayout(
        this.channel?.layout || firstPublicLayout || this.layouts()[0],
      );
    } else if (this.channel?.layout) {
      this.setLayout(this.channel?.layout);
    }
  }

  setFormState() {
    this.channelForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.channelForm) {
          // console.log('channelForm.valueChanges', this.channelForm);
          this.pristine.emit(this.channelForm.pristine);
          this.valid.emit(
            this.channelForm.valid,
            /*this.showSelectionOnly
            ? this.channelForm.controls.name.valid
            : this.channelForm.valid*/
          );
          this.values.emit(this.channelForm.value);
        }
      });
  }

  async setControlValues() {
    if (this.channel) {
      this.channelForm.patchValue({
        name: this.channel.name,
        description: this.channel.description,
        layoutId: this.channel.layout?.id || null,
        layoutName: this.channel.layout?.name || null,
        eventStart: this.channel.eventStart,
        eventEnd: this.channel.eventEnd,
      });

      this.valid.emit(this.channelForm.valid);
    }
  }

  getLayoutId() {
    const form = this.channelForm.value as ChannelForm;
    return form.layoutId;
  }
  getLayout() {
    const layoutId = this.getLayoutId();
    if (layoutId) {
      return this.layouts()?.find((x) => x.id === layoutId);
    }
    return undefined;
  }
  getLayoutName() {
    const layout = this.getLayout();
    if (layout) {
      return layout.name;
    }

    return 'Select layout';
  }

  setLayout(layout: Layout) {
    this.channelForm.controls.layoutId.markAsDirty();
    this.channelForm.controls.layoutName.markAsDirty();

    this.channelForm.patchValue({
      layoutId: layout.id,
      layoutName: layout.name,
      layoutOrientation:
        layout.source?.canvas?.height &&
        layout.source?.canvas?.width &&
        layout.source?.canvas?.height > layout.source?.canvas?.width
          ? 'portrait'
          : 'landscape',
    });
  }

  // event can be any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitForm(event: any, values: IChannelForm) {
    console.log('submitForm', event, values);
    if (this.channel && this.channel.id) {
      values.id = this.channel.id;
      this.submitted.emit(values);
      this.resetForm();
    }
  }

  get canEditLayout() {
    const layout = this.getLayout();
    return (
      (this.currentUserService.isSuperAdmin ||
        this.currentUserService.isProfileManager) &&
      layout &&
      !layout.public
    );
  }

  async editLayout(addNew = false) {
    const layoutId = addNew ? 'new' : this.getLayoutId();

    const layout = await this.openEditLayout(layoutId);

    if (layout) {
      await this.initLayouts();

      // sync form name, no need to set it dirty
      this.channelForm.patchValue({
        layoutId: layout.id,
        layoutName: layout.name,
      });
      // sync page
      // this.submitForm('save', this.channelForm.value as IChannelForm);
    }
  }

  async openEditLayout(id: Maybe<string>) {
    // console.log('open dialog');
    return new Promise<Maybe<Layout>>((resolve, reject) => {
      // console.log('promise start');
      const modalRef = this.modalService.open(LayoutManageDialogComponent, {
        windowClass: 'cesdk-modal',
        backdrop: 'static',
      });

      modalRef.componentInstance.id = id;

      modalRef.result.then(
        (value: Maybe<Layout>) => {
          console.log('resolve promise', value);
          this.submitForm('save', this.channelForm.value as IChannelForm);
          resolve(value);
        },
        () => {
          console.log('dismiss with no value');
          resolve(null);
        },
      );
      // console.log('promise end');
    });
  }

  onSelectedChange(event: string) {
    console.log('onSelectedChange', event);

    this.explorerSelectedRegion.emit(event);
  }
  getPublicLayouts(layouts: Maybe<Layout[]>) {
    return layouts?.filter((x) => x.public);
  }
}
