<form
  [id]="formId"
  [formGroup]="parentFormGroup ?? channelForm"
  (submit)="submitForm($event, channelForm.value)"
  (reset)="resetForm()"
>
  @if (settingsManager) {
    <div
      [formGroup]="channelForm"
      id="settingsManagerTemplate"
      class="form-group mb-2"
      [ngClass]="controlGroupClass"
    >
      <label class="form-label" for="name">{{ 'NAME' | translate }}*</label>
      @if (
        channelForm &&
        !channelForm.controls['name'].pristine &&
        channelForm.controls['name'].hasError('required')
      ) {
        <span class="error">
          {{ 'NAME_IS_REQUIRED' | translate }}
        </span>
      }
      <input
        type="text"
        name="name"
        class="form-control form-control-dark"
        formControlName="name"
      />
    </div>
    <div
      [formGroup]="channelForm"
      class="form-group mb-2"
      [ngClass]="controlGroupClass"
    >
      <label class="form-label" for="description">{{
        'DESCRIPTION' | translate
      }}</label>
      <input
        type="text"
        name="description"
        class="form-control form-control-dark"
        formControlName="description"
      />
    </div>
    @if (isEvent) {
      <div
        [formGroup]="channelForm"
        class="form-group d-flex flex-wrap my-4"
        [ngClass]="controlGroupClass"
      >
        <div class="py-2 me-5">
          <label class="form-label" for="eventStart">
            {{ 'START' | translate }}
          </label>

          <des-date-time
            [formGroup]="channelForm"
            formControlName="eventStart"
            [showSecond]="false"
          />
        </div>
        <div class="py-2">
          <label class="form-label" for="eventEnd">{{
            'EVENT_END' | translate
          }}</label>
          <des-date-time
            [formGroup]="channelForm"
            [minDateTime]="channelForm.controls['eventStart'].value"
            formControlName="eventEnd"
            [showSecond]="false"
          />
        </div>
        @if (
          channelForm &&
          !channelForm.controls['eventStart'].pristine &&
          channelForm.controls['eventStart'].hasError('required')
        ) {
          <span class="error">
            {{ 'START_IS_REQUIRED' | translate }}
          </span>
        }
      </div>
    }
  }
  <!-- MARK: Layout Manager -->
  @if (layoutManager) {
    @if (canSetLayout) {
      <div
        id="layoutManagerTemplate"
        class="form-group my-5"
        [ngClass]="controlGroupClass"
      >
        <label class="form-label">{{ 'LAYOUT' | translate }}</label>
        <div class="d-flex align-items-center py-1">
          @if (layout && showMiniLayoutExplorer) {
            <div
              class="px-2"
              [ngbPopover]="popChannelLayoutExplorer"
              triggers="mouseenter:mouseleave"
            >
              <app-layout-explorer
                [layout]="layout"
                [boundingBox]="'40px'"
                [canSelect]="false"
                [showText]="false"
                (selectedChange)="onSelectedChange($event)"
              ></app-layout-explorer>
            </div>
          }
          <ng-template #popChannelLayoutExplorer>
            <app-layout-explorer
              [layout]="layout"
              [boundingBox]="'250px'"
              [canSelect]="false"
            ></app-layout-explorer>
          </ng-template>
          <div ngbDropdown container="body" class="flex-grow-1">
            <button
              type="button"
              class="form-control form-control-dark d-flex justify-content-between align-items-center"
              ngbDropdownToggle
            >
              {{
                channelForm.value.layoutName
                  ? channelForm.value.layoutName
                  : 'no layout'
              }}
            </button>
            <div ngbDropdownMenu class="shadowed-box text-white p-2">
              <div class="my-1 px-1">
                {{ 'LAYOUTS_PUBLIC_LIST' | translate }}
              </div>
              <div class="dropdown-item-container">
                @for (publicLayout of publicLayouts; track publicLayout.id) {
                  <span
                    ngbDropdownItem
                    [ngbPopover]="popExplorer"
                    triggers="mouseenter:mouseleave"
                    placement="end"
                    class="pointer dropdown-items"
                    (click)="setLayout(publicLayout)"
                    >{{ publicLayout.name }}</span
                  >
                  <ng-template #popExplorer>
                    <app-layout-explorer
                      [layout]="publicLayout"
                      [boundingBox]="'250px'"
                      [canSelect]="false"
                    ></app-layout-explorer>
                  </ng-template>
                }
                <div class="mt-3 mb-1 px-1">
                  {{ 'LAYOUTS_CUSTOM_LIST' | translate }}
                </div>
                @for (customLayout of customLayouts; track customLayout.id) {
                  <span
                    ngbDropdownItem
                    [ngbPopover]="popExplorer"
                    triggers="mouseenter:mouseleave"
                    placement="end"
                    class="pointer dropdown-items"
                    (click)="setLayout(customLayout)"
                    >{{ customLayout.name }}</span
                  >
                  <ng-template #popExplorer>
                    <app-layout-explorer
                      [layout]="customLayout"
                      [boundingBox]="'250px'"
                      [canSelect]="false"
                    ></app-layout-explorer>
                  </ng-template>
                }
                <hr />
                <span
                  ngbDropdownItem
                  class="pointer dropdown-items"
                  (click)="editLayout(true)"
                  >+ {{ 'NEW_LAYOUT' | translate }}</span
                >
              </div>
            </div>
          </div>
          <div
            class="d-none d-lg-block"
            ngbTooltip="{{
              canEditLayout
                ? ('EDIT_LAYOUT_TT' | translate)
                : ('EDIT_LAYOUT_TT_DISABLED' | translate)
            }}"
          >
            <button
              [disabled]="!canEditLayout"
              (click)="editLayout()"
              class="ms-2 btn btn-outline-primary"
            >
              {{ 'EDIT' | translate }}
            </button>
          </div>
        </div>
      </div>
    }
    @if (layout && !showMiniLayoutExplorer) {
      <div class="d-flex justify-content-center h-100" #layoutExplorer>
        <app-layout-explorer
          [layout]="layout"
          [boundingBox]="explorerSize"
          [canSelect]="canSelectRegions"
          [selected]="selectedRegion"
          (selectedChange)="onSelectedChange($event)"
        ></app-layout-explorer>
      </div>
    }
  }

  <!-- TODO: Hidden until content is implemented -->
  @if (isDashboardView) {
    <div [hidden]="true" class="row mt-2 border-top">
      <div class="col">
        <label class="form-label" for="images">{{
          'channel.CHANNEL_IMAGES' | translate
        }}</label>
      </div>
      <div class="col">
        <button class="btn btn-outline-primary float-end border-bottom">
          {{ 'UPLOAD' | translate }}
        </button>
      </div>
    </div>
    <div [hidden]="true" class="row mt-2">
      <div class="col">
        <h5 class="text-white text-center">{{ 'NO_IMAGES' | translate }}</h5>
      </div>
    </div>
  }
</form>
