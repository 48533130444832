import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Layout, Maybe, UpdateLayoutInput } from '@designage/gql';
import { ConfirmDialogComponent } from '@desquare/components/common/src/modals/confirm-dialog.component';
import { ILayoutEditorCanvas, ILayoutEditorSource } from '@desquare/interfaces';
import {
  CurrentUserService,
  EncryptionService,
  LayoutDataService,
  LayoutEditorService,
  ToasterService,
} from '@desquare/services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubSink } from 'subsink';
import { TranslatePipe } from '@ngx-translate/core';
import { LayoutFormComponent } from '../layout-form/layout-form.component';

@Component({
  standalone: true,
  imports: [LayoutFormComponent, TranslatePipe],
  selector: 'app-layout-manage',
  template: `<div class="ps-2 h-100 d-flex flex-column">
    <!-- header section -->
    <div class="row mb-3">
      <div
        class="text-truncate col-12 d-flex align-items-center justify-content-between sliding-panel-header"
      >
        <label class="d-inline pointer-text">
          {{ layoutManageForm.value?.detailSettingsForm?.name || '' }}
        </label>
        <div class="pe-5 me-3">
          <button
            class="btn btn-md btn-outline-success mx-2"
            (click)="submit(true)"
            [hidden]="!isModal"
          >
            {{ 'SAVE_AND_CLOSE' | translate }}
          </button>
          <button
            class="btn btn-md btn-outline-success mx-2"
            (click)="submit()"
            [hidden]="isModal"
          >
            {{ 'SAVE' | translate }}
          </button>

          <button
            class="btn btn-md btn-outline-danger mx-2"
            [hidden]="isModal"
            (click)="onDeleteButtonClicked()"
          >
            {{ 'DELETE' | translate }}
          </button>
        </div>
      </div>
    </div>

    <!-- layout form -->
    <app-layout-form
      class="h-100 pt-4"
      [parentFormGroup]="layoutManageForm"
      [isManageMode]="true"
    ></app-layout-form>
    <!-- <app-zone-splitter></app-zone-splitter> -->
  </div> `,
  styles: [``],
  providers: [LayoutEditorService],
})
export class LayoutManageComponent implements OnInit, OnDestroy {
  subs = new SubSink();

  @Input() isModal = false;
  @Input() inlineLayoutId = '';

  _layout!: Maybe<Layout>;
  set layout(input: Maybe<Layout>) {
    this._layout = input;

    if (input) {
      this.setFormValues();
    }
  }
  get layout() {
    return this._layout;
  }

  layoutManageForm = new UntypedFormGroup({});

  constructor(
    private currentUserService: CurrentUserService,
    private encryptionService: EncryptionService,
    private layoutDataService: LayoutDataService,
    private layoutEditorService: LayoutEditorService,
    private modal: NgbActiveModal,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private toasterService: ToasterService,
  ) {}

  ngOnInit(): void {
    this.initVariables();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  initVariables() {
    if (this.inlineLayoutId !== '') {
      this.initLayout(this.inlineLayoutId);
    } else {
      this.subs.sink = this.route.params.subscribe((params) => {
        const encryptedLayoutId = params.layoutId;
        // console.error('no encrypted layoutId'); // DEBUG
        if (encryptedLayoutId) {
          const layoutId = this.encryptionService.decrypt(encryptedLayoutId);
          this.initLayout(layoutId);
        }
      });
    }
  }

  async initLayout(layoutId: string) {
    this.layout = null;

    this.subs.sink = this.layoutDataService
      .getLayoutById(layoutId)
      .subscribe((result) => {
        const { data } = result;
        const { layout } = data;

        this.layout = layout;
      });
  }

  /**
   * the forms mentioned in this function will be initialized
   * in their respective components
   */
  setFormValues() {
    if (!this.layout) return;

    const DETAIL_SETTINGS_FORM_GROUP_NAME = 'detailSettingsForm';
    const detailSettingsFormGroup =
      this.layoutManageForm.controls[DETAIL_SETTINGS_FORM_GROUP_NAME];

    if (detailSettingsFormGroup) {
      detailSettingsFormGroup.patchValue({
        name: this.layout.name,
        description: this.layout.description,
        width: this.layout.source?.canvas?.width,
        height: this.layout.source?.canvas?.height,
      });
    }

    const SOURCE_FORM_GROUP_NAME = 'layoutSourceForm';
    const layoutSourceFormGroup =
      this.layoutManageForm.controls[SOURCE_FORM_GROUP_NAME];

    if (layoutSourceFormGroup) {
      // TODO: fix later, type assertion here is a temporary solution
      const source: ILayoutEditorSource = this.layout
        .source as ILayoutEditorSource;
      layoutSourceFormGroup.patchValue({
        sceneUrl: source.sceneUrl,
        cloudinaryPublicId: source.cloudinaryPublicId,
        regionBlocks: source.regionBlocks,
      });
    }
  }

  async submit(closeModal = false) {
    if (!this.layout?.id) return console.error('no layoutId');
    // stop submit if one of the forms inside layoutCreateForm is invalid
    if (this.layoutManageForm.invalid) {
      this.toasterService.error('Invalid Form');
      return;
    }

    const { detailSettingsForm, layoutSourceForm } =
      this.layoutManageForm.value;

    // the type assertion here is bad practice, but as of now its the only way to deal
    // with types inside forms, consider upgrading to Angular 14 in the future where
    // Typed Forms exists: https://angular.io/guide/typed-forms
    const previousSource = layoutSourceForm as ILayoutEditorSource;
    const publicId = previousSource.cloudinaryPublicId;

    const updateLayoutNameInput: string = detailSettingsForm.name;
    const updateLayoutDescriptionInput: string = detailSettingsForm.description;

    // save scene to cloudinary using the source public Id
    const cloudinaryUploadResponse =
      await this.layoutEditorService.saveSceneToCloudinary(publicId);

    // create canvas
    const canvas: ILayoutEditorCanvas =
      this.layoutEditorService.extractToILayoutEditorCanvas();

    // create new source
    const source: ILayoutEditorSource =
      this.layoutEditorService.toLayoutEditorSource(
        cloudinaryUploadResponse,
        canvas,
      );

    // create UpdateLayoutInput
    const updateLayoutInput: UpdateLayoutInput = {
      layoutId: this.layout.id,
      name: updateLayoutNameInput,
      description: updateLayoutDescriptionInput,
      source,
      // TODO here: linking to channel not yet implemented
      // channelIds:
    };

    this.subs.sink = this.layoutDataService
      .updateLayout(updateLayoutInput)
      .subscribe((result) => {
        const { data } = result;
        if (data) {
          const { isSuccessful, layout } = data.updateLayout;

          if (isSuccessful) {
            this.toasterService.success('UPDATE_LAYOUT_SUCCESS');

            // refetch layouts
            this.layoutDataService.profileLayoutsQuery?.refetch({
              profileId: this.currentUserService.currentProfile?.id,
            });

            // console.log('updated layout: ', layout); // DEBUG
            this.layout = layout;
            if (closeModal && this.isModal) {
              this.modal.close(layout);
            }
          }
        }
      });
  }

  onDeleteButtonClicked() {
    if (!this.layout) return console.error('no layout');
    // show confirmation dialog
    const modalRef = this.modalService.open(ConfirmDialogComponent, {
      size: 'sm',
    });
    modalRef.componentInstance.headerText = 'Delete Layout';
    modalRef.componentInstance.bodyText = `Are you sure you want to delete this layout?: ${this.layout.name}`;

    modalRef.result.then((value: boolean) => {
      if (!this.layout) return console.error('no layout');

      if (value) {
        // delete layout
        this.subs.sink = this.layoutDataService
          .deleteLayoutById(this.layout.id)
          .subscribe((result) => {
            const { data } = result;
            if (data?.deleteLayout.isSuccessful) {
              this.toasterService.success('DELETE_LAYOUT_SUCCESS');

              // refetch layouts
              this.layoutDataService.profileLayoutsQuery?.refetch({
                profileId: this.currentUserService.currentProfile?.id,
              });

              // close slide panel and navigate back to list page
              this.router.navigate(['layouts']);
            }
          });
      }
    });
  }
}
