import {
  Component,
  EventEmitter,
  Input,
  Output,
  Self,
  Signal,
  WritableSignal,
  computed,
  signal,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { getTodayDateString } from '@desquare/utils';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerComponent } from '../datepicker/datepicker.component';
import { TimepickerComponent } from '../timepicker/timepicker.component';

@Component({
  standalone: true,
  imports: [DatepickerComponent, TimepickerComponent],
  selector: 'des-date-time',
  template: `
    <!-- minDate: {{ minDateTime }}, minTime: {{ minTimeSgn() }} -->
    <div class="d-flex">
      @if (date && maxDate && minDate) {
        <designage-datepicker
          [disabled]="!enableDate"
          [(date)]="date"
          [maxDate]="maxDate"
          [minDate]="minDate"
          (dateChange)="onDateChange()"
        />
      }
      @if (time) {
        <designage-timepicker
          class="ms-2"
          *ngIf="enableTime"
          [minTime]="minTimeSgn()"
          [(time)]="time"
          [showSecond]="showSecond"
          (click)="$event.stopPropagation()"
          (timeChange)="onTimeChange()"
        />
      }
    </div>
  `,
})
export class SmartDateTimeComponent implements ControlValueAccessor {
  @Input() disabled = false;

  /** value to/from calendar */
  dateS: WritableSignal<string | undefined> = signal(undefined);
  get date() {
    return this.dateS() || '';
  }
  set date(value: string | undefined) {
    this.dateS.set(value);
  }
  /** value to/from time picker */
  time?: string;

  minDateTimeSgn: WritableSignal<string> = signal('');
  minTimeSgn: Signal<string> = computed(() => {
    const min = this.minDateTimeSgn();
    const date = this.dateS() || '';
    if (this.enableTime && min !== '' && date.length > 0) {
      // if is the same date as minDate
      if (min.startsWith(date)) {
        const parts = min.split(' ');
        return parts[1] || '';
      }
    }
    return '';
  });
  @Input() set minDateTime(value: string) {
    this.minDateTimeSgn.set(value);
    this.minDate = value;
  }
  get minDateTime() {
    return this.minDateTimeSgn();
  }

  @Input()
  set value(value: string) {
    this._value = value;
    if (value) {
      if (this.enableDate && this.enableTime) {
        const [d, t] = value.split('T');
        this.date = d;
        this.time = t;
      }
    }
  }
  get value() {
    return this._value;
  }
  @Output() valueChange = new EventEmitter<string>();

  @Input() asInputField = true;
  // Calendar config
  @Input() enableDate = true;
  @Input() maxDate?: string;
  minDate?: string;

  // time config
  @Input() enableTime = true;
  @Input() showSecond = true;

  @Input() hourStep = 1;
  @Input() minuteStep = 1;
  @Input() secondStep = 1;

  _value!: string;

  constructor(@Self() private control: NgControl) {
    this.control.valueAccessor = this;
  }

  //#region FORM BINDING

  onChange = (value: string) => {};

  onTouched = () => {};

  touched = false;

  public writeValue(value: string) {
    this._value = value;
    if (value && value !== '') {
      const parts = value.split(' ');
      this.date = parts[0] || '';
      this.time = parts[1] || '';
    }
  }

  public registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  //#endregion

  getDateString() {
    if (!this.enableDate) return '';
    return this.date || getTodayDateString();
  }
  getTimeString() {
    if (!this.enableTime) return '';
    return this.time || '00:00:00';
  }

  /** compose final value when time or date change and call needed events*/
  setOutputValue() {
    const dtSep = this.enableDate && this.enableTime ? ' ' : '';
    const date = this.getDateString();
    const time = this.getTimeString();

    if (!date && !time) {
      this.writeValue('');
    } else {
      this.writeValue(`${date}${dtSep}${time}`);
    }

    this.onChange(this._value);
    this.markAsTouched();
  }

  onDateChange() {
    console.log('onDateChange', this.date);
    this.setOutputValue();
  }

  getTime(value: string): NgbTimeStruct {
    // HH:mm:ss
    const timeElements = value.split(':');
    return {
      hour: parseInt(timeElements[0], 10),
      minute: parseInt(timeElements[1], 10),
      second: this.showSecond ? parseInt(timeElements[2], 10) : 0,
    };
  }

  onTimeChange() {
    this.setOutputValue();
  }

  onSetTime() {
    /*
    const newTime = this.getDisplayTime();
    if (this.time !== newTime) {
      console.log('newTime', newTime);
      this.timeChange.emit(newTime);
    }*/
  }

  popoverShown() {
    console.log('popoverShown');
  }
}
